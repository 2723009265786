import { CAR_MODULE_PATHS, NEWS_MODULE_PATHS } from '@apps/config';

export type CommonPathType = {
  title: string;
  path: string;
  children?: string[];
};

export type MegaMenuType = {
  title: string;
  children?: CommonPathType[];
};

export const topLeftMenus: CommonPathType[] = [
  {
    title: 'News',
    path: '/',
    children: ['/', ...NEWS_MODULE_PATHS],
  },
  {
    title: 'Car Info',
    path: '/cars',
    children: CAR_MODULE_PATHS,
  },
];

export const carModuleMegaMenu: MegaMenuType[] = [
  {
    title: 'Pages',
    children: topLeftMenus,
  },
];
