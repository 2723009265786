/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllEvOverview, Sorting } from '@apps/config/API/new-car.config';
import { EvOverviewType } from '@apps/screens/EvOverviewScreen/props';
import Cookies from 'js-cookie';

type SortParams = {
  key?: string;
  value?: Sorting;
};

export type FetchMoreProps = {
  page: number;
  onCompleted?: (response: EvOverviewType) => void;
};

interface EvOverviewDataContextProps {
  data: any[];
  ids: string[];
  sorting?: SortParams;
  addId: (newId: string) => void;
  removeId: (id: string) => void;
  setSorting: (state: SortParams) => void;
  fetchMore: (event: FetchMoreProps) => Promise<void>;
  isFetching: boolean;
  isLoading: boolean;
}

const EvOverviewDataContext = createContext<
  EvOverviewDataContextProps | undefined
>(undefined);

export const EvOverviewProvider: React.FC<any> = ({ children }) => {
  // ================== STATES
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [sorting, setSorting] = useState<SortParams>();
  const [ids, setIds] = useState<string[]>(Cookies.getJSON('pinned') || []);

  // ================== EFFECTS
  useEffect(() => {
    fetchMultipleData(ids, sorting);
  }, [ids, sorting]);

  // ================== EVENTS
  const fetchMore = async ({ page, onCompleted }: FetchMoreProps) => {
    setIsFetching(true);
    try {
      if (isFetching) return;

      const response = await getAllEvOverview({
        ids: ids?.join(','),
        sort_key: sorting?.key,
        sort_value: sorting?.value,
        page,
      });
      setIsFetching(false);
      onCompleted?.(response);
    } catch (error) {
      setIsFetching(false);
      console.error('Error fetching more data:', error);
    }
  };

  const fetchMultipleData = async (ids: string[], sorting?: SortParams) => {
    setIsLoading(true);
    try {
      const results = await getAllEvOverview({
        ids: ids?.join(','),
        sort_key: sorting?.key,
        sort_value: sorting?.value,
        page: 1,
      });
      setData(results?.data || []);
      Cookies.set('pinned', JSON.stringify(ids));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const addId = (newId: string) => {
    setIds((prev) => {
      return [...prev, newId];
    });
  };

  const removeId = (id: string) => {
    setIds((prev) => {
      return prev.filter((existingId) => existingId !== id);
    });
  };

  // ================== CONTEXT PROVIDER
  return (
    <EvOverviewDataContext.Provider
      value={{
        data,
        ids,
        sorting,
        isFetching,
        isLoading,
        setSorting,
        addId,
        removeId,
        fetchMore,
      }}
    >
      {children}
    </EvOverviewDataContext.Provider>
  );
};

export const useEvOverviewData = () => {
  const context = useContext(EvOverviewDataContext);
  if (!context) {
    throw new Error('useEvOverviewData must be used within a PinnedProvider');
  }
  return context;
};
