/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateQueryToUrl } from '@apps/utils/query-to-url';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { BASE_ENDPOINT } from '../constant';

export const getNewCarById = async ({ id }: { id: number }): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/new_car_feature?new_car_feature_id=${id}`;

  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getAllBrandsInitial = async (): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/new_car_init`;
  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export type Sorting = 'desc' | 'asc';

type EvOverviewFilterProps = {
  ids: string;
  sort_key?: string;
  sort_value?: Sorting;
  page?: number;
};

export const getAllEvOverview = async (
  props: EvOverviewFilterProps
): Promise<any> => {
  const { ids, sort_key, sort_value, page } = props;
  const baseUrl = `${BASE_ENDPOINT}`;
  let url = `${baseUrl}/car_info/ev_overview`;
  const currentPage = page ? page?.toString() : undefined;

  // Create an object to hold the query parameters
  const params = new URLSearchParams();

  const setParams = (key?: string, value?: string) => {
    if (isEmpty(value) || isEmpty(key) || !key || !value) return;
    params.append(key, value);
  };

  // Add query parameters conditionally
  if (!isEmpty(ids)) {
    params.append('sort_by_ids', ids);
  }
  setParams(sort_key, sort_value);
  setParams('page', currentPage);

  // If there are any parameters, append them to the base URL
  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  try {
    const config = {
      method: 'get',
      url,
    };

    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getCarByBrand = async ({
  newCarSlug,
  brand,
}: {
  newCarSlug?: string;
  brand?: string;
}): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  let url = `${baseUrl}/new_car`;

  if (brand) {
    url = `${baseUrl}/new_car/${brand}`;
  }
  if (newCarSlug) {
    url = `${baseUrl}/new_car/${brand}/${newCarSlug}`;
  }
  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getNewCarByFilter = async ({
  query,
  path = 'new_car',
}: {
  query: any;
  path?: string;
}): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/${path}`;
  const filterUrl = generateQueryToUrl(query, url);
  try {
    const config = {
      method: 'get',
      url: filterUrl,
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getCarNewLaunch = async (): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/car_info/new_launch`;
  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getEvTest = async (): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/car_info/ev_test`;
  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getRanking = async (): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}`;
  const url = `${baseUrl}/car_info/ranking`;
  try {
    const config = {
      method: 'get',
      url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};
